export enum QueryKey {
  COMPANIES = 'pa-shell-companies',
  EXPORT_USERS_TABLE = 'pa-shell-export-users-table',
  INIT_COMPANY = 'pa-shell-init-company',
  INIT_LEGACY_USER = 'pa-shell-init-legacy-user', // TODO: remove after deleting connections with old PA (iframe)
  INIT_SCOPES = 'pa-shell-init-scopes',
  INIT_USER = 'pa-shell-init-user',
  PROFILES = 'pa-shell-profiles',
  SEARCH_CITIES = 'pa-shell-search-cities',
  SEARCH_HOUSES = 'pa-shell-search-houses',
  SEARCH_STREETS = 'pa-shell-search-streets',
  USER_CARD = 'pa-shell-user-card',
  USERS_TABLE = 'pa-shell-users-table',
}

export enum TableKey {
  USERS = 'pa-shell-table-users',
}
